import * as React from "react"
import { StoreProvider } from "./src/context/store-context"
import Nav from "./src/components/nav.js"
import Alert from "./src/components/alert.js"

export const wrapRootElement = ({ element }) => (
  <>
    <StoreProvider>
      { element }
      <Nav />
      <Alert />
    </StoreProvider>
  </>
)
