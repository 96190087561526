import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import Button from "./button.js"


const Alert = (props) => {

  const [open, setOpen] = useState(false);

  useEffect( () => {
    const timer = setTimeout( () => {
      setOpen(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []); // empty array tells React to cleanup and never run again

  return (
    <div className={ `alert ${open ? "open" : ""}` }>
      <div className="close-button"
        onClick={ () => setOpen(false) }
        ></div>
      <h2>We're in the News</h2>
      <p>One Street has been honored with a front-page story in the Raleigh News &amp; Observer.</p>
      <a href="https://www.newsobserver.com/news/local/article269477192.html" target="_blank">
        <Button
          // onClick={ ()=>{
          //   setOpen(false);
          //   navigate("https://www.newsobserver.com/news/local/article269477192.html");
          // } }
          {...props}
          >
            Read the Story
        </Button>
      </a>
    </div>
  )
}

export default Alert
