import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import logo from "../images/logo.svg"

const Nav = (props) => {

  const data = useStaticQuery(
    graphql`
    query NavQuery {
      desktop: file(name: {eq: "map-transparent-20"}) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    `
  )
  const imageData = data.desktop.childImageSharp.fluid;

  return (
    <BackgroundImage
      Tag="div"
      fluid={ imageData }
      backgroundColor={`#FFD91A`}
      className="side-bar"
    >
      <div className="logo-wrapper">
        <img className="logo" alt="One Street in Raleigh" src={ logo } />
      </div>
      <nav>
        <div className="nav-item"><Link to="/"><div className="inner">About</div></Link></div>
        <div className="nav-item"><Link to="/gallery/"><div className="inner">Gallery</div></Link></div>
        <div className="nav-item"><Link to="/contact/"><div className="inner">Contact</div></Link></div>
        <div className="nav-item"><Link to="/shop/"><div className="inner">Shop</div></Link></div>
      </nav>
      <p className="fine-print"><Link to="https://tiptopstudio.com">A Tip Top Studio <br/>Project</Link></p>
    </BackgroundImage>
  )
}

export default Nav
